import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AutoComplete } from 'primereact/autocomplete';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import './customcss.css';

const LocationSearch = ({updateSelectedLocation, resizeWidth}) => {
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const searchLocation = async (event) => {
        try {
            const response = await axios.get(process.env.REACT_APP_GEO_API_URL, {
                params: {
                    q: event.query,
                    limit: 5,
                    appid: process.env.REACT_APP_GEO_KEY
                }
            });
            const locationResults = response.data.map(loc => ({
                name: `${loc.name ? loc.name : " "}, ${loc.country ? loc.country : " "}`,
                lat: loc.lat ? loc.lat : "0",
                lon: loc.lon ? loc.lon : "0"
            }));

            setLocations(locationResults);
            updateSelectedLocation(locationResults);

        } catch (error) {
            setLocations([]);
        }
    };

    const locationTemplate = (item) => {
        return (
            <div className="location-item">
                {item.name}
            </div>
        );
    };

    const searchPhraseStart = () => {
        return resizeWidth > 992
            ? 'The Weather in'
            : 'Search: ';
    };

    const searchPhraseEnd = () => {
        return resizeWidth > 992
            ? 'is...'
            : '';
    };

    return (
        <div className="Unit flex align-items-start justify-content-left h-auto mb-2">
            <div className="hero-menu-type">{searchPhraseStart()}</div>
            <div className="custom-autocomplete">
        <div>
            <AutoComplete
                className="custom-autocomplete"
                value={selectedLocation}
                suggestions={locations}
                placeholder="Location"
                completeMethod={searchLocation}
                field="name"
                itemTemplate={locationTemplate}
                onChange={(e) => setSelectedLocation(e.value)}
                onSelect={(e) => {
                    setSelectedLocation(e.value);
                    updateSelectedLocation([e.value]);
                }}
            />
        </div>
            </div>
            <div className='hero-menu-type'>{searchPhraseEnd()}</div>
        </div>
    );
};

export default LocationSearch;
