import React, { useState, useEffect } from 'react';
import axios from "axios";

function DailyForecastComponent({selectedLocation, updateCurrentDayForecast, resizeWidth}) {
    const [dayTemperatures, setDayTemperatures] = useState([]);
    const [weekDay, setWeekDay] = useState([]);
    const [icon, setIcon] = useState('');

    useEffect(() => {

        if (selectedLocation && selectedLocation.length > 0 ) {
            const locationName = selectedLocation[0].name;
            axios.get(process.env.REACT_APP_FORECAST_API_URL, {
                params: {
                    q: locationName,
                    cnt: 5,
                    appid: process.env.REACT_APP_GLOBAL_KEY,
                    units: 'metric'
                }
            })
                .then(response => {
                    if (response.data && response.data.list) {
                        const dayTemps = response.data.list.map(item => Math.round(item.temp.day));
                        const weekdays = response.data.list.map(item => getWeekday(item.dt));
                        const iconCode = response.data.list.map(item => item.weather[0].icon);
                        const currentDayForecastForAI = JSON.stringify(response.data.list[0]);
                        updateCurrentDayForecast(currentDayForecastForAI);
                        setDayTemperatures(dayTemps);
                        setWeekDay(weekdays);
                        setIcon(iconCode);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                });
        }
    }, [selectedLocation, updateCurrentDayForecast, resizeWidth]);

    const getWeekday = (timestamp) => {

        const days = (resizeWidth && resizeWidth > 992)
            ? ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
            : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return days[new Date(timestamp * 1000).getDay()];
    };

    return (
        <div className="daily-forecast-container flex flex-wrap w-12 sm:w-12 md:w-12 lg:w-12 xl:w-12 mb-4 justify-content-between md:justify-content-start gap-2">
            {dayTemperatures.map((temp, index) => (
                <div
                    key={index}
                    className="flex-1 max-w-4rem sm:max-w-5rem md:max-w-8rem sm:w-3rem md:w-9rem justify-content-center bg-blue-200 border-round-md md:border-round-xl border-1 md:border-2 border-blue-700"
                >
                    <div className="flex flex-column w-full">
                        <div
                            key={index}
                            className="daily-forecast-container-day flex align-items-center justify-content-center mt-2 md:mt-3 md:mt-3"
                        >
                            {weekDay[index]}
                        </div>
                        <div className="flex align-items-center justify-content-center">
                            {icon && (
                                <img
                                    key={index}
                                    className='h-3rem sm:h-3rem md:h-4rem lg:h-4rem my-2 sm:my-2 md:my-2 lg:my-2'
                                    src={`https://tie-weather-icons.vercel.app/v8/${icon[index]}.png`}
                                    alt="Weather Icon"
                                />
                            )}
                        </div>
                        <div className="daily-forecast-container-temp flex align-items-center justify-content-center mb-1 md:mb-3 top-50">
                            {temp}°
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
export default DailyForecastComponent;
