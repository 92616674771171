import React, { useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import 'primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import './customcss.css';
import LocationSearch from "./LocationSearch";
import DailyForecastComponent from "./DailyForecastComponent";
import HourlyForecastComponent from "./HourlyForecastComponent";
import WeatherMap from "./WeatherMap";
import NaturalLanguageForecast from "./NaturalLanguageForecast";
import WeatherTTS from "./WeatherTTS";

function App() {
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [currentDayForecastForAI, setCurrentDayForecastForAI] = useState(null);
    const updateSelectedLocation = (newLocation) => setSelectedLocation(newLocation);
    const updateCurrentDayForecastForAI = (forecast) => setCurrentDayForecastForAI(forecast);
    const { width, ref } = useResizeDetector({
        handleWidth: true,
        refreshMode: 'debounce',
        refreshRate: 100
    });

    return (
     <div ref={ref} className='h-screen min-h-full mb-4'>
     <div className="app-css w-auto m-2 sm:m-3 bg-blue-100 border-round-xl min-h-screen">
            <div className='flex flex-column'>
                <div className="flex flex-column m-2 sm:m-3 md:m-8 pb-5">
                    <div className="Unit flex align-items-start justify-content-left h-auto mt-3 ml-2 md:ml-0 md:mt-0 mb-2">
                        <LocationSearch resizeWidth={width} updateSelectedLocation={updateSelectedLocation} />
                    </div>
                    <div className="Unit flex align-items-center justify-content-left w-full border-round mt-3">
                        <NaturalLanguageForecast selectedLocation={selectedLocation} locationAI={currentDayForecastForAI}/>
                        {/*<WeatherTTS selectedLocation={selectedLocation} locationAI={currentDayForecastForAI}/>*/}
                    </div>
                    <div className="Unit flex align-items-center justify-content-left w-12 sm:w-10 md:w-10 lg:w-10 xl:w-10 border-round">
                        <div className="flex flex-column w-full">
                            <div className="flex align-items-start component-header">
                                {selectedLocation ? (
                                        "5-DAY FORECAST"
                                ) : ""}
                            </div>
                            <div className="flex align-items-start">
                                <DailyForecastComponent selectedLocation={selectedLocation} updateCurrentDayForecast={updateCurrentDayForecastForAI}/>
                            </div>
                        </div>
                    </div>
                    <div className="Unit flex align-items-center justify-content-left w-12 sm:w-10 md:w-10 lg:w-10 xl:w-10 border-round">
                        <div className="flex flex-column w-full">
                            <div className="flex align-items-start component-header">
                                {selectedLocation ? (
                                    "HOURLY FORECAST"
                                ) : ""}
                            </div>
                            <div className="flex align-items-start">
                                <HourlyForecastComponent selectedLocation={selectedLocation}/>
                            </div>
                        </div>
                    </div>
                    <div className="Unit flex align-items-center justify-content-left w-12 sm:w-10 border-round mt-5">
                        {selectedLocation ? (
                            <WeatherMap selectedLocation={selectedLocation} updateSelectedLocation={updateSelectedLocation}/>
                        ) : ""}
                    </div>
                </div>
            </div>
        </div>
     </div>
  );
}
export default App;
