import React, {useEffect, useState} from "react";
import { MapContainer, TileLayer } from "react-leaflet";

function WeatherMap({selectedLocation}) {
    const apiKey = process.env.REACT_APP_GLOBAL_KEY;
    const defaultLat = 0.0; // Default latitude value
    const defaultLon = 0.0; // Default longitude value
    const [position, setPosition] = useState([defaultLat, defaultLon]);
    const mapKey = position.toString();
    const zoom = 8; // Zoom level to view the whole country
    const mapType = "temp_new"; // Change weather map type

    useEffect(() => {
        if (selectedLocation && selectedLocation.length > 0) {
            setPosition([
                selectedLocation[0].lat ? selectedLocation[0].lat : defaultLat,
                selectedLocation[0].lon ? selectedLocation[0].lon : defaultLon
            ]);
        }
    }, [selectedLocation]);

    return (
        <div className="app-css border-round-xl w-12">
            <MapContainer
                key={mapKey}
                center={position}
                zoom={zoom}
                style={{ height: "55vh", width: "100%" }}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <TileLayer
                    url={`https://tile.openweathermap.org/map/${mapType}/{z}/{x}/{y}.png?appid=${apiKey}`}
                />
            </MapContainer>{" "}
        </div>
    );
}

export default WeatherMap;