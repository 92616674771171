import React, { useEffect, useState } from 'react';
import axios from "axios";
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { Card } from 'primereact/card';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import 'primeflex/primeflex.css';

function HourlyForecastComponent({ selectedLocation }) {
    const [hourlyForecast, setHourlyForecast] = useState([]);
    useEffect(() => {

        if (selectedLocation && selectedLocation.length > 0) {
            const locationName = selectedLocation[0].name;
            axios.get(process.env.REACT_APP_HOURLY_FORECAST_URL, {
                params: {
                    appid: process.env.REACT_APP_GLOBAL_KEY,
                    units: 'metric',
                    q: locationName
                }
            })
                .then(response => {
                    if (response.data && response.data.list[0].main.temp) {
                        const todaysForecast = response.data.list.slice(0, 24);
                        setHourlyForecast(todaysForecast);
                    }
                })
                .catch(error => {
                    console.error('Error fetching hourly data: ', error);
                });
        }
    }, [selectedLocation]);

    return (
        !hourlyForecast.length ? (
            <div>
                <Card title="No Location Selected">
                    <p className="m-0">
                        Please enter the city name into the search bar above.
                    </p>
                </Card>
            </div>
        ) : (
            <div
                className="hourly-forecast-container p-2 bg-blue-200 w-12 border-round-xl align-items-center justify-content-center gap-2">
                <ScrollMenu>
                    {hourlyForecast.map((forecast, index) => (
                        <div key={index}
                             className="menu-item flex max-w-full h-6rem md:h-9rem pl-2 md:pl-3 pr-2 md:pr-3 bg-blue-300 border-2 border-blue-700 align-items-center justify-content-center border-round m-1">
                            <div className="forecast-item">
                                <div
                                    className='flex align-items-center justify-content-center sm:my-1'>{new Date(forecast.dt * 1000).getHours()}:00
                                </div>
                                {forecast.weather && forecast.weather[0] && (
                                    <img className='h-3rem md:h-4rem my-1 sm:my-0 md:my-2 lg:my-2'
                                         src={`https://tie-weather-icons.vercel.app/v8/${forecast.weather[0].icon}.png`}
                                         alt="Weather Icon"/>
                                )}
                                <div
                                    className='flex align-items-center justify-content-center'>{Math.round(forecast.main.temp)}°
                                </div>
                            </div>
                        </div>
                    ))}
                </ScrollMenu>
            </div>
        )
    );
}

export default HourlyForecastComponent;
